@import "./base.scss";

.confirm {
  &-panel {
    @extend %selection-panel;
    position: absolute;
    margin: auto;
    top: 164px;
    right: 0;
    left: 0;
  }

  &-title {
    font-size: 24px;
    color: $gray80;
    margin-bottom: 21px;
  }

  &-field {
    div[class^="InputGroup-label"] {
      font-family: $font-lato !important;
      font-size: 14px !important;
      color: $gray60 !important;
      font-weight: bold !important;
    }
  }
  
  &-error {
    display: none;
    margin-bottom: 17px !important;
    font-size: 14px;
    line-height: 1.14;
  }

  &-submit {
    margin-top: 40px !important;
  }
}
