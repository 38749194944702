@import "../../styles/base.scss";

body .video-detail {
  &-panel {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 348px;
    margin-bottom: 0 !important;
    overflow-y: scroll;
    width: 543px;
    border: solid 1px $gray20;
  }
  
  &-titlebar {
    height: 91px;
    background-color: $white;
    border-bottom: solid 1px $gray20;
  }
  
  &-title {
    font-size: 20px;
    font-weight: 500;
  }
  
  &-close {
    color: $gray80;
  }
}