@import "../../styles/base.scss";

.data-panel {
  margin: 1rem -1rem;
  padding: 0rem 1rem;
  border-bottom: solid 1px $gray20;
  
  &-title {
    height: 45px;
  }
  
  &-image {
    img {
      height: auto !important;
      width: 100% !important;
    }
  }
  
  &-table {
    width: 100%;
  }
}

.cell-highlight {
  text-transform: uppercase;
  color: $bc-blue !important;
}

.transparent-border {
  border-bottom: solid 1px transparent;
}