@import "./base.scss";


.adduser {
  &-panel {
    width: 576px;
    margin: 99px 53px;
  }
  
  &-background {
    display: block;
    background-color: $white;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  &-error {
    display: none;
    margin-bottom: 17px !important;
    font-size: 14px;
    line-height: 1.14;
  }
}