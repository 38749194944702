@import "../../styles/base.scss";

body .header {
  background-color: $gray90;
  display: inline-flex;
  width: 100%;
  @include all-heights($header-height);

  > div {
    display: flex;
    align-items: center;
  }
  &-logo {
    margin-left: $outer-margin;

    img {
      max-height: 61px;
      width: 140px;
    }
  }

  &-tabs {
    text-transform: uppercase;
    margin-left: 100px;

    [role="tablist"] {
      margin-bottom: 0;
    }

    [class^="Tabs-spacer"] {
      display: none;
    }
  }

  &-tab {
    margin-right: 14px;
    font-family: $font-lato;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 5px solid transparent;

    &[aria-selected="false"] {
      color: $white;
    }

    &[aria-selected="true"] {
      border-color: $orange;
      color: $white;
    }

    &[aria-selected="false"]:hover {
      border-bottom: 5px solid transparent;
    }
  }

  &-menus {
    margin: 0 $outer-margin 0 auto;

    [role="menu"] ul {
      border: 1px solid $black;
    }
  }

  &-menu-button {
    background-color: $gray90 !important;
    border-color: $gray90 !important;
    font-family: $font-lato !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: $white !important;
    padding: 0 !important;
  }
  
  &-user-menu {
    margin: 0px 22px 6px 58px;
  }  
  
  &-user-label {
    display: inline-block;
    text-transform: lowercase !important;
    font-weight: 500;
    text-align: right;
  }

  &-admin-menu {
    margin-right: 30px;
  }
}