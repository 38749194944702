@import "./base.scss";

body .dashboard {
  &-separator {
    height: 77px;
    width: 100%;
    padding: 20px 40px;
    background-color: $white;
    display: inline-flex;
  }
  
  &-filter-button {  
    button {
      width: 98px;
      height: 36px;
      border: 4px solid $gray17_5 !important;
      background-color: $gray17_5 !important;
      color: $gray80 !important;
      font-size: 15px !important;
      font-weight: normal !important;
      line-height: 1.07 !important;
      padding: 0 4px !important;
    }
  }
  
  &-filter-column {
    min-width: 178px;
    background-color: $black2;
    border-radius: 4px;
  }
  
  &-filter-checkbox-span {
    opacity: 0.8;
    color: $white;
  }
  
  &-filter-label {
    font-weight: bold;
    color: $white;
  }
  
  &-search {
    display: inline-block;
  }
  
  &-table {
    width: 100%;
  }
  
  &-loadmore {
    position: relative !important;
    left: 50%;
    transform: translateX(-50%) !important;
    margin-top: 22px !important;
    width: 160px;
    height: 30px;
    border-radius: 2px !important;
    border: solid 1px $gray30 !important;
    background-color: $gray17_5 !important;
    color: $teal !important;
    
    span {
      font-size: 30px;
      line-height: 1;
    }
  }
  
  &-footer {
    height: 125px;
    background-color: $white;
  }
}

.text-bold {
  font-weight: bold;
}

.icon-cell {
  font-size: 14px;
  
  i {
    margin-right: 5px;
  }
}

.info-button {
  width: 26px;
  height: 20px;
  padding: 0 0 !important;
  line-height: 19px !important;
  border: none !important;
  border-radius: 2px !important;
  background-color: $gray17_5 !important;
  margin-right: 10px !important;
  
  i {
    font-size: 7px;
    color: #409ca9;
  }
}