@import "./base.scss";

.login {
  &-panel {
    @extend %selection-panel;
    position: absolute;
    margin: auto;
    top: 164px;
    right: 0;
    left: 0;
  }

  &-title {
    font-size: 24px;
    color: $gray80;
    margin-bottom: 4px;
  }

  &-subtitle {
    font-size: 15px;
    line-height: 1.07;
    color: $gray80;
    margin-bottom: 24px;
  }

  &-field {
    div[class^="InputGroup-label"] {
      font-family: $font-lato !important;
      font-size: 14px !important;
      color: $gray60 !important;
      font-weight: bold !important;
    }
  }

  &-error {
    display: none;
    margin-bottom: 17px !important;
    font-size: 14px;
    line-height: 1.14;
  }

  &-submit {
    margin-top: 21px !important;
  }
}

#login {
  &-resetpassword {
    display: block;
    margin-top: 13px;
  }
}
