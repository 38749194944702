@import "../../styles/base.scss";

.number-board-block {
  width: 100%;
  height: 181px;
  border: solid 1px $gray20;
  background-color: $white;
  
  &-number {
    width: 100%;
    height: 128px;
    font-size: 100px;
    font-weight: 600;
    text-align: center;
  }
  
  &-title {
    width: 100%;
    height: 27px;
    font-size: 22px;
    text-align: center;
    color: #444444;
    
    i {
      margin-right: 5px;
    }
  }
}