@import "./base.scss";

.job {
  &-panel {
    @extend %selection-panel;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: fit-content;
    }

    li {
      font-family: $font-roboto;
      font-size: 15px;
      color: $teal;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 13px;
      }
    }
  }
  
  &-background {
    display: block;
    background-color: $white;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
