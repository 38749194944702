// Base Colors
$black: black;
$black2: #333333;
$white: white;
$gray5: #F6F6F6;
$gray10: #f4f4f4;
$gray15: #e1e1e1;
$gray17_5: #eeeeee;
$gray20: #DDDDDD;
$gray30: #CCCCCC;
$gray40: #999999;
$gray50: #888888;
$gray55: #808080;
$gray60: #666666;
$gray70: #484848;
$gray80: #444444;
$gray90: #333333;
$teal: #079BB3;
$magenta: #BA2453;
$orange: #f67e33;
$pink: #D32781;
$light-pink: #f0d8e0;
$dark-red: #b33664;
$green: #A2B00C;
$yellow: #FDE86A;
$orange: #EF6C2A;
$baby-blue1: #EFFBFF;
$baby-blue2: #DCF3FB;
$baby-blue3: #1ab9d5;
$bc-blue: #409ca9;
$light-green: #a5c13f;
$dark-blue1: #409ca9;


// Constants
$font-titilium: 'Titillium Web', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-lato:  'Lato', Helvetica, Arial, sans-serif;

$header-height: 90px;
$outer-margin: 22px;

%selection-panel {
  width: 451px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin: 53px;
}

// Mixins
@mixin all-heights($value) {
  min-height: $value;
  height: $value;
  max-height: $value;
}

@mixin all-widths($value) {
  min-width: $value;
  width: $value;
  max-width: $value;
}

@mixin equal-dimensions($value) {
  height: $value;
  width: $value;
}

// Global Styles
body {
  font-family: $font-lato;
  background-color: #eee;
}

a {
  text-decoration: none;
  color: $bc-blue;
}

div:focus, button:focus {
  outline: none !important;
}

.autoHeight {
  height: auto !important;
}

.language-code {
  background-color: $gray5;
  border: 1px solid $gray15;
  font-family: $font-lato;
  font-size: 11.5px;
  margin-left: 6px;
}

.name-id {
  div:first-child {
    a {
      text-decoration: none;
      color: $teal;
    }
  }

  div:last-child {
    font-size: 12px;
    color: $gray40;
    margin-top: 3px;
  }
}
